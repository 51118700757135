<script setup>
import { ref } from "vue"
import { canAddSite, getProjectByCmiId } from "@/services/Project"
import { updateSitesFromServer, canEditSite } from "@/services/Site"
import {
  showFlashMessage,
  getLastUpdateInfo,
  areCachedDataStale,
  setStalenessNotificationTime,
} from "@/utils/GlobalState"
import LastSynced from "@/components/LastSynced.vue"
import useServerRefresher from "@/composables/useServerRefresher"
import ContentRefresher from "@/components/ContentRefresher.vue"
import ExpandCollapse from "@/components/ExpandCollapse.vue"
import PageLoading from "@/components/PageLoading.vue"
import { gettext } from "@/utils/Translation"

const { $gettext } = gettext
const haveResults = ref(false)
const project = ref(null)
const { refreshUnderway, serverRefresher } = useServerRefresher()
const lastSynced = ref(undefined)
let staleNotificationQueryKey = "sitesAndProjects"

const props = defineProps({
  projectCmiId: {
    type: Number,
    required: true,
  },
})

async function getSitesServer(wasManuallyTriggered = false) {
  await serverRefresher(updateSitesFromServer, [], staleNotificationQueryKey, wasManuallyTriggered)
    .catch(async error => {
      if (error.name === "CONNECTIVITY_REQUIRED") {
        lastSynced.value = await getLastUpdateInfo({ type: "sitesAndProjects" })
        const msg = $gettext("You do not appear to be online.")
        let details = ""
        if (lastSynced.value) {
          details = $gettext("The list of sites shown here was last updated %{ date }.", { date: lastSynced.value })
        }
        else {
          details = $gettext("The list of sites for this project has never been updated from the server.")
        }
        showFlashMessage({ msg: `${msg} ${details}`, class: "is-warning", appearanceDelay: 0 })
        setStalenessNotificationTime(staleNotificationQueryKey)
      }
      else {
        throw error
      }
    })
  await setProjectAndSites()
}

async function setProjectAndSites() {
  project.value = await getProjectByCmiId(props.projectCmiId, { withSites: true })
  project.value.sites.forEach(async (site) => site.canEdit = await canEditSite(site))
  lastSynced.value = await getLastUpdateInfo({ type: "sitesAndProjects" })
}

async function getData() {
  if (await areCachedDataStale({ type: "sitesAndProjects" })) {
    await getSitesServer()
  }
  else {
    await setProjectAndSites()
  }
  haveResults.value = true
}

getData()
</script>

<template>
  <section class="site-list">
    <div class="is-flex is-justify-content-flex-end pr-4">
      <ContentRefresher
        :refresh-function="getSitesServer"
        :refresh-underway="refreshUnderway"
      />
    </div>
    <template v-if="haveResults">
      <div
        v-for="site in project.sites"
        :key="site.cmiId"
        class="panel-block is-flex-direction-column has-text-left"
      >
        <ExpandCollapse>
          <template #label>
            <h2 class="subtitle">
              {{ site.name }}
            </h2>
          </template>
          <template #content>
            <div class="buttons">
              <router-link
                class="button is-fullwidth is-medium is-responsive is-primary"
                :to="{ name: 'SiteHome', params: { siteCmiId: site.cmiId } }"
              >
                {{ $gettext("Site Home") }}
              </router-link>
              <router-link
                v-if="site.canEdit"
                class="button is-fullwidth is-medium is-responsive is-primary"
                :to="{ name: 'SiteManage', params: { siteCmiId: site.cmiId } }"
              >
                {{ $gettext("Manage Site") }}
              </router-link>
            </div>
          </template>
        </ExpandCollapse>
      </div>
      <div
        v-if="!project.sites.length"
        class="panel-block"
      >
        <p class="muted is-italic">
          {{ $gettext("No sites have been added yet.") }}
        </p>
      </div>
    </template>
    <PageLoading v-else />
    <div
      v-if="haveResults && canAddSite(project)"
      class="panel-block"
    >
      <router-link
        class="button is-fullwidth is-medium is-responsive is-primary"
        :to="{ name: 'SiteCreate', params: { projectCmiId } }"
      >
        {{ $gettext("Add New Site") }}
      </router-link>
    </div>
    <LastSynced
      :last-synced="lastSynced"
      :in-panel="true"
    />
  </section>
</template>
<style scoped>
.site-list h2.subtitle {
  text-transform: none;
  font-weight: bold;
}
.site-list .buttons {
  margin-top: 0.5rem;
}
.site-list .panel-block {
  align-items: normal;
}
</style>
