<script lang="ts" setup>
  import { useRoute, useRouter } from "vue-router"

  import { getAccountAndProjectRoleSchema, togglePasswordVisibility } from "@/schemas/Account"
  import { createProjectAccount, getProjectByCmiId } from "@/services/Project"
  import { setCurrentViewContext } from "@/utils/GlobalState"
  import OnlineRequiredForm from "@/components/OnlineRequiredForm.vue"

  const route = useRoute()
  const router = useRouter()
  const projectCmiId = parseInt(route.params.projectCmiId as string)
  const uploadFunc = (data) => createProjectAccount(projectCmiId, data)
  const successRoute = { name: "ProjectAccounts", params: { projectCmiId } }

  // An abuse of this function; we need to pass into the "data" prop of the form
  // the function that handles clicks on the eye icon.
  async function getFormData() {
    return { togglePasswordVisibility }
  }

  async function getData() {
    const project = await getProjectByCmiId(projectCmiId)
    if (project) {
      setCurrentViewContext(project, "project")
    }
    else {
      return router.replace({ name: "SiteList" })
    }
  }
  getData()
</script>
<template>
  <article>
    <OnlineRequiredForm
      :page-title="$gettext('Add an Account')"
      :schema="getAccountAndProjectRoleSchema()"
      :form-data-retrieval-func="getFormData"
      :upload-func="uploadFunc"
      :success-route="successRoute"
    />
  </article>
</template>
