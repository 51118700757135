<script setup>
import { useRoute } from "vue-router"

import { setCurrentViewContext, getAccountInfo } from "@/utils/GlobalState"
import { getProjectSchema } from "@/schemas/Project"
import { uploadProject, getProjectByCmiId } from "@/services/Project"
import { gettext } from "@/utils/Translation"
import OnlineRequiredForm from "@/components/OnlineRequiredForm.vue"

const { $gettext } = gettext
const accountInfo = getAccountInfo()
const route = useRoute()
const successRoute = { name: "SiteList" }
// If null, that means we're creating a new project. Otherwise editing existing.
const projectCmiId = parseInt(route.params.projectCmiId)
const pageTitle = projectCmiId ? $gettext("Update project") : "Add a new project"
let formDataRetrievalFunc = null // Don't need one if we're creating.
let schema = getProjectSchema()

// Some users can edit projects but can't add new ones. Let's assume that if
// they're not able to add new ones, they're not SPOON staff, or at least,
// shouldn't be seeing the "english name" field.
if (!accountInfo.canAddProjects) {
  schema = schema.filter(item => item.id != "englishName")
}

if (projectCmiId) {
  formDataRetrievalFunc = async () => {
    const project = await getProjectByCmiId(projectCmiId, { fromServer: true })
    setCurrentViewContext(project, "project")
    return project
  }
}
else {
  setCurrentViewContext()
}
</script>

<template>
  <article>
    <OnlineRequiredForm
      :page-title="pageTitle"
      :schema="schema"
      :form-data-retrieval-func="formDataRetrievalFunc"
      :upload-func="uploadProject"
      :success-route="successRoute"
    />
  </article>
</template>
