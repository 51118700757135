<template>
  <h3 class="muted-header">
    {{ $gettext("Change role") }}
  </h3>
  <div class="columns block">
    <template v-for="role in roles">
      <div
        v-if="role.value != account.roleId"
        :key="role.value"
        class="column"
      >
        <button
          :class="{
            button: true,
            'is-primary': true,
            'is-loading': clickedButton == `change-${role.value}-${account.accountId}`,
          }"
          @click.prevent="changeRole(account, role.value)"
        >
          {{ role.changeLabel }}
        </button>
      </div>
    </template>
  </div>
  <h3 class="muted-header">
    {{ $gettext("Remove role") }}
  </h3>
  <div class="columns block">
    <div class="column">
      <button
        :class="{
          button: true,
          'is-primary': true,
          'is-loading': clickedButton == `remove-${account.accountId}-keep-sites`,
        }"
        @click.prevent="removeFromProject(account.accountId, { retainSitePermissions: true})"
      >
        {{ $gettext("Remove role but retain site permissions") }}
      </button>
    </div>
    <div class="column">
      <button
        :class="{
          button: true,
          'is-primary': true,
          'is-loading': clickedButton == `remove-${account.accountId}-remove-sites`,
        }"
        @click.prevent="removeFromProject(account.accountId, { retainSitePermissions: false})"
      >
        {{ $gettext("Remove role AND site permissions") }}
      </button>
    </div>
  </div>
</template>
<script lang="ts" setup>
  import { ref } from "vue"
  import { useRoute } from "vue-router"
  import { gettext } from "@/utils/Translation"

  import { showFlashMessage } from "@/utils/GlobalState"
  import { removeAccountRoleFromProject, updateAccountRoleForProject } from "@/services/Project"
  import { getProjectRolesOptions } from "@/utils/Options"

  const emit = defineEmits(["removeAccount"])
  const { $gettext } = gettext
  const route = useRoute()
  const projectCmiId = parseInt(route.params.projectCmiId as string)

  defineProps({
    account: {
      type: Object,
      required: true,
    },
  })
  
  const clickedButton = ref(null)
  const roles = getProjectRolesOptions()
  const roleMap = {}
  roles.forEach(role => roleMap[role.value] = role.label)

  async function removeFromProject(accountId: string, { retainSitePermissions }) {
    let confText: string
    if (retainSitePermissions) {
      confText = "Are you sure? This person will no longer be manage this project, but will be able to manage all of its sites."
    }
    else {
      confText = "Are you sure? This person will no longer be manage this project OR access any of its sites."
    }
    if (confirm($gettext(confText))) {
      clickedButton.value = `remove-${accountId}-${retainSitePermissions ? 'keep-sites' : 'remove-sites'}`
      await removeAccountRoleFromProject(accountId, projectCmiId, { retainSitePermissions }).then(() => {
        emit("removeAccount", accountId)
        showFlashMessage({ msg: $gettext("Your changes have been saved."), class: "is-success" })
      })
    }
  }

  async function changeRole(account, roleId: string) {
    clickedButton.value = `change-${roleId}-${account.accountId}`
    await updateAccountRoleForProject(account.accountId, projectCmiId, roleId).then(() => {
      account.roleId = roleId
      account.roleLabel = roleMap[roleId]
      showFlashMessage({ msg: $gettext("Your changes have been saved."), class: "is-success" })
    })
  }
</script>
<style scoped>
  .account-controls .button {
    width: 100%;
  }
  .muted-header {
    color: gray;
    text-transform: uppercase;
    font-weight: normal;
    font-size: 1rem;
  }
</style>
