<!-- From https://www.iconfinder.com/icons/3994438/cloud_download_network_server_storage_icon -->
<template>
  <svg
    aria-hidden="true"
    class="icon-download"
    height="100%"
    width="100%"
    viewBox="0 0 96 96"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title />
    <g>
      <path
        d="M61.7578,67.7578,54,75.5156V54a6,6,0,0,0-12,0V75.5156l-7.7578-7.7578a5.9994,5.9994,0,0,0-8.4844,8.4844l18,18a5.9979,5.9979,0,0,0,8.4844,0l18-18a5.9994,5.9994,0,0,0-8.4844-8.4844Z"
      />
      <path
        d="M78,24h-.6035A29.9916,29.9916,0,0,0,20.52,18,21.0358,21.0358,0,0,0,0,39,20.7039,20.7039,0,0,0,16.7637,59.2617,6,6,0,0,0,19.2363,47.52,8.7977,8.7977,0,0,1,12,39a9.0136,9.0136,0,0,1,9-9,18.9753,18.9753,0,0,1,2.1328.5391,5.9794,5.9794,0,0,0,4.6231-.6914,6.0438,6.0438,0,0,0,2.7187-3.8086A17.9815,17.9815,0,0,1,66,30a5.9966,5.9966,0,0,0,6,6h6a6,6,0,0,1,0,12,6,6,0,0,0,0,12,18,18,0,0,0,0-36Z"
      />
    </g>
  </svg>
</template>
