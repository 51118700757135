<script lang="ts" setup>
  import { ref } from "vue"

  import PageLoading from "@/components/PageLoading.vue"
  import IconSpinner from "@/components/svg/IconSpinner.vue"

  import { setCurrentViewContext, showFlashMessage } from "@/utils/GlobalState"
  import { handlePendingUploads, getContentPendingUpload } from "@/services/Upload"
  import { gettext } from "@/utils/Translation"
  import { isOnline } from "@/utils/Utilities"

  const { $gettext } = gettext

  const pageReady = ref(false)
  const contentPendingUpload = ref(null)
  const showUploadButton = ref(isOnline())
  const uploadButtonTriggered = ref(false)

  async function attemptUploads() {
    uploadButtonTriggered.value = true

    let msg: string
    handlePendingUploads()
      .then(() => {
        msg = $gettext("All content has been uploaded to the server.")
        showFlashMessage({ msg, class: "is-success" })
        getData()
      })
      .catch((error) => {
        if (error.name === "CONNECTIVITY_REQUIRED" || error.message === "ERR_NETWORK") {
          msg = $gettext("You do not appear to be online. You may try again later.")
        }
        else {
          msg = $gettext("Something unexpected happened. You may try again later.")
        }
        showFlashMessage({ msg })
      })
      .finally(() => showUploadButton.value = false)
  }

  async function getData() {
    contentPendingUpload.value = await getContentPendingUpload()
    pageReady.value = true
  }

  setCurrentViewContext()
  getData()
</script>
<template>
  <article>
    <h1 class="title">
      {{ $gettext("Content needing to be uploaded") }}
    </h1>

    <template v-if="pageReady">
      <template v-if="contentPendingUpload">
        <div
          v-if="showUploadButton"
          class="has-text-centered"
        >
          <a
            class="button is-fullwidth mb-3"
            @click.once="attemptUploads()"
          >
            <template v-if="!uploadButtonTriggered">
              {{ $gettext("Try to upload this content") }}
            </template>
            <IconSpinner v-else />
          </a>
        </div>
        <p>
          {{ $gettext("The following items need to be uploaded to the server.") }}
          <template v-if="!isOnline()">
            {{ $gettext("An internet connection is required to do so.") }}
          </template>
        </p>
        <ul>
          <li
            v-for="category in contentPendingUpload"
            :key="category.label"
            class="upload-category"
          >
            <h2>
              {{ category.label }}
              <span class="muted">({{ category.count }})</span>
            </h2>
            <ul
              v-if="category.items"
              class="list"
            >
              <li
                v-for="item in category.items"
                :key="item.id"
              >
                {{ item.label }}
              </li>
            </ul>
          </li>
        </ul>
      </template>
      <p v-else>
        {{ $gettext("Nothing needs to be uploaded.") }}
      </p>
    </template>
    <PageLoading v-else />
  </article>
</template>
<style lang="scss" scoped>
  li.upload-category {
    margin-bottom: 2em;
  }
  .icon-spinner {
    height: 1em;
  }
</style>
