<template>
  <section v-if="isOnline()">
    <PageLoading
      v-if="!accounts"
      wrapper-classes="padded"
      :with-text="true"
    />
    <template v-else>
      <template v-if="accounts.length">
        <PanelLabelValuePair
          v-for="account in accounts"
          :key="account.id"
          :label="account.name"
          :value="account.roleLabel"
        />
      </template>
      <div
        v-else
        class="panel-block"
      >
        <p class="muted is-italic">
          {{ $gettext("This project does not yet have any members.") }}
        </p>
      </div>
    </template>
    <div class="panel-block">
      <router-link
        class="button is-fullwidth is-medium is-responsive is-primary"
        :to="{ name: 'ProjectAccounts', params: { projectCmiId} }"
      >
        <IconPencil />
        <span>{{ $gettext("Manage Accounts & Roles") }}</span>
      </router-link>
    </div>
  </section>
  <section
    v-else
    style="padding: 1em"
  >
    <p>
      {{ $gettext("An internet connection is required for this page.") }}
    </p>
    <p>
      {{ $gettext("Your device does not seem to be connected to the internet. Please check your connection and try again by revisiting this page.") }}
    </p>
  </section>
</template>
<script lang="ts" setup>
  import { ref } from "vue"
  import IconPencil from "@/components/svg/IconPencil.vue"
  import { isOnline } from "@/utils/Utilities"
  import { getProjectAccountList } from "@/services/Project"
  import PageLoading from "@/components/PageLoading.vue"
  import PanelLabelValuePair from "@/components/sites/PanelLabelValuePair.vue"

  const props = defineProps({
    projectCmiId: {
      type: Number,
      required: true,
    },
  })

  const accounts = ref(null)

  async function getData() {
    if (isOnline()) {
      accounts.value = await getProjectAccountList(props.projectCmiId)
    }
  }

  getData()
</script>