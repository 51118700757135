import { getCountryOptions } from "@/utils/Options"
import { gettext } from "@/utils/Translation"

const { $gettext } = gettext

export function getProjectSchema() {
  return [
    {
      $formkit: "hidden",
      name: "id",
      value: "$id",
    },
    {
      $formkit: "hidden",
      name: "cmiId",
      value: "$cmiId",
    },
    {
      $formkit: "text",
      id: "name",
      name: "name",
      value: "$name",
      label: $gettext("Name"),
      validation: "required",
    },
    {
      $formkit: "text",
      id: "englishName",
      name: "englishName",
      value: "$englishName",
      label: "English Name",
      help: "Only seen by SPOON staff. Use this if the primary name is not in English–or if it is ambiguous.",
    },
    {
      $formkit: "select",
      id: "country",
      name: "country",
      value: "$country",
      label: $gettext("Country"),
      validation: "required",
      options: getCountryOptions(),
      "inner-class": "select",
    },
  ]
}
